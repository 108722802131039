import './App.css';
import React, { useState,useEffect } from "react";
import Slider from "react-slick";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ott01 from './img/ott01.png';
import ott02 from './img/ott02.png';
import ott03 from './img/ott03.png';
import notice01 from './img/notice01.png';
import notice02 from './img/notice02.png';
import notice03 from './img/notice03.png';
import thk1 from './img/thk1.png';
import thk2 from './img/thk2.png';
import moop1 from './img/moop1.png';
import moop2 from './img/moop2.png';
import moop3 from './img/moop3.png';
import ld1 from './img/ld1.PNG';
import ld2 from './img/ld2.PNG';
import ld3 from './img/ld3.PNG';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [projectList, setProjectList] = useState([
    { no: 1, pjtName: "MYFLIX", exp: "ott 컨텐츠 플랫폼인 넷플릭스를 모방한 웹 애플리케이션입니다. ", url: "http://www.kjms.shop/ottClone/", popupText: "MYFLIX는 넷플릭스의 핵심 기능을 클론하여 실제 프로젝트와 유사한 환경을 경험할 수 있는 포트폴리오 프로젝트입니다. React와 Axios, TMDB API를 활용하여 최신 영화 및 TV 프로그램 데이터를 제공하며, 사용자에게 직관적이고 매력적인 UI를 제공합니다. 자세한 내용은 MYFLIX 프로젝트 페이지에서 확인할 수 있습니다. (영상재생은 제공하지 않습니다.)", subTitle: '넷플릭스 클론 코딩', images: [ott01, ott02, ott03] },
    { no: 2, pjtName: "ThinkShare", exp: "ThinkShare는 사용자가 글을 작성하고 읽을 수 있는 웹 애플리케이션입니다.", url: "http://www.kjms.shop/notice-board/#/", popupText: "ThinkShare는 사용자가 글을 작성하고 읽을 수 있는 웹 애플리케이션입니다. 이 애플리케이션은 React로 구성된 프론트엔드와 Node.js로 구축된 백엔드 포인트를 사용하여 구현되었습니다. 주요 기능으로는 사용자 계정을 통해 안전하게 로그인할 수 있는 로그인 기능, 원하는 글을 쉽게 찾을 수 있는 강력한 검색 기능, 사용자가 선호하는 테마로 웹 애플리케이션의 외관을 변경할 수 있는 테마 변경 기능, 그리고 개인 프로필을 관리하고 작성한 글을 한눈에 볼 수 있는 마이페이지 기능을 제공합니다.", subTitle: '게시판 웹 애플리케이션', images: [notice01, notice02, notice03] },
    { no: 3, pjtName: "Daily Hot Keyword", exp: "Daily Hot Keyword는 오늘, 대한민국에서 가장 많이 검색된 검색어를 보여주는 웹 애플리케이션입니다.", url: "http://www.kjms.shop/TodayHotKeyword/", popupText: "Daily Hot Keyword는 대한민국에서 가장 많이 검색된 검색어를 실시간으로 보여주는 웹 애플리케이션으로, 사용자는 최신 트렌드를 쉽게 확인할 수 있습니다. 이 애플리케이션은 React를 사용하여 사용자 인터페이스를 구성하고, Google Trends API에서 데이터를 가져와 Node.js 서버에 저장한 후 이를 프론트엔드에 전달합니다. 사용자들은 매일 업데이트되는 인기 검색어 목록을 통해 현재의 관심사와 이슈를 빠르게 파악할 수 있습니다.", subTitle: '인기 검색어 통계 웹 어플리케이션', images: [thk1,thk2] },
    { no: 4, pjtName: "Management Of Our Project", exp: "Management Of Our Project는 프로젝트 별로 효과적으로 관리하기 위한 웹 애플리케이션입니다.", url: "http://www.kjms.shop/moop/", popupText: "Management Of Our Project는 프로젝트 별로 효과적으로 관리하기 위한 웹 애플리케이션으로, 프로젝트 참여자끼리의 일정, 업무를 효과적으로 분담할 수 있습니다. 이 애플리케이션은 React를 사용하여 사용자 인터페이스를 구성하고, Node.js서버로 데이터베이스에 접근하여 데이터를 전달합니다. 또한 사용자들은 업무에 담당자를 지정하여 '내 업무' 메뉴에서 사용자가 담당자로 지정된 업무를 한번에 확인할 수 있습니다.", subTitle: '프로젝트 관리 웹 애플리케이션', images: [moop1,moop2,moop3] },
  ]);
  const [toyProjectList, setToyProjectList] = useState([
    { no: 1, pjtName: "LuckyDefense", exp: "당신의 운을 시험하세요.", url: "http://www.kjms.shop/luckyDefense/", popupText: "Lucky Defense는 3가지의 간단한 선택지의 미니게임입니다. 각각 10%, 60%, 100% 확률로 확률에 비례하게 무기를 강화할 수 있습니다. Lucky Defense는 React와 Axios외에 다른 라이브러리없이 Node.js로 구축된 백엔드 서버와 랭킹을 확인하고, 등록할 수 있습니다.", subTitle: '3가지 선택의 간단한 미니게임', images: [ld1, ld2, ld3] },
    // { no: 2, pjtName: "QuizForum", exp: "여러가지 어려운 퀴즈를 풀어보세요.", url: "http://www.kjms.shop/luckyDefense/", popupText: "Lucky Defense는 3가지의 간단한 선택지의 미니게임입니다. 각각 10%, 60%, 100% 확률로 확률에 비례하게 무기를 강화할 수 있습니다. Lucky Defense는 React와 Axios외에 다른 라이브러리없이 Node.js로 구축된 백엔드 서버와 랭킹을 확인하고, 등록할 수 있습니다.", subTitle: '3가지 선택의 간단한 미니게임', images: [ld1, ld2, ld3] },
  ]);

  const popupShow = (project) => {
    setPopupData({
      pjtName: project.pjtName,
      subTitle: project.subTitle,
      popupText: project.popupText,
      url: project.url,
      images: project.images,
    });
    setShowPopup(true);
    document.body.classList.add('no-scroll');
  };

  const popupHide = () => {
    setShowPopup(false);
    setPopupData({});
    document.body.classList.remove('no-scroll');
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    AOS.init({
      duration: 500, // 애니메이션 지속 시간 (밀리초)
      easing: 'ease-in-out', // 애니메이션 스타일
      once: true, // 스크롤 후 다시 나타나지 않도록 설정
    });
    axios.get('http://portfoliokjm.cafe24app.com/api/test')
    .then(response => {
      // 응답 데이터를 출력합니다.
      console.log(response.data);
    })
    .catch(error => {
      // 에러가 발생했을 경우 처리합니다.
      console.error("에러 발생:", error);
    });
  }, []);
  return (
    <div className="App">
      <section className="sec_01">
        <div className="container h100">
          <div className="center_box">
            <div className="graphic_wrap">
              <h2 className="mt_minus20">
                <span className='upAnimate an1'>프론트엔드</span> <span className='upAnimate an2'>개발자</span> <p className="bold upAnimate an3">김정민입니다.</p>
              </h2>
              <div className="sub_text">
                창의적인 해결책을 찾아 끊임없이 도전하며,<br />
                책임감을 갖고 일하는 개발자입니다.
              </div>
            </div>
          </div>
          <div className="about_arrow">
            About me
          </div>
        </div>
      </section>
      <section className="sec_02">
        <div className="container">
          <div className="introduce_wrap">
            <h2 data-aos="fade-up">What About <span className="bold">Me</span></h2>
            <div className="intro_flex">
              <div className="list">
                <div data-aos="fade-up" data-aos-delay="100">
                  <h3>
                    웹퍼블리셔에서 개발자로
                  </h3>
                  <p className="exp">
                    웹 퍼블리셔로 일하면서 저는 웹사이트의 시각적 디자인과 사용자 경험에 깊은 관심을 가지게 되었습니다. 다양한 프로젝트를 통해 HTML, CSS, JavaScript를 다루며, 사용자가 인터랙티브하고 매력적인 웹 페이지를 경험할 수 있도록 최선을 다했습니다. 이러한 작업들은 매우 보람 있었지만, 점점 더 복잡하고 역동적인 웹 애플리케이션의 개발 과정에 흥미가 생기기 시작했습니다.
                  </p>
                </div>
              </div>
              <div className="list">
                <div data-aos="fade-up" data-aos-delay="200">
                <h3>
                  기술적 도전과 성장
                </h3>
                <p className="exp">
                  웹 퍼블리셔로서의 역할은 주로 정적인 콘텐츠를 다루는 일이 많았지만, 프론트엔드 개발은 사용자 인터페이스와 사용자 경험을 더욱 동적으로 개선할 수 있는 기회를 제공했습니다. 저는 웹사이트가 단순한 정보 제공을 넘어서서 사용자와 상호작용할 수 있는 도구로 변모하는 과정을 보며, 더 깊이 있는 기술적 지식을 쌓고 싶었습니다. React, Vue.js와 같은 현대적인 프론트엔드 프레임워크를 배우고 적용하는 과정에서 새로운 도전과 성장을 경험할 수 있었습니다.
                </p>
                </div>
              </div>
              <div className="list">
                <div data-aos="fade-up" data-aos-delay="300">
                  <h3>
                    더 큰 영향력과 책임
                  </h3>
                  <p className="exp">
                    프론트엔드 개발자는 단순히 디자인을 구현하는 역할을 넘어, 전체 애플리케이션의 성능과 최적화, 접근성까지 책임지게 됩니다. 이러한 책임감은 저에게 큰 동기부여가 되었고, 웹 퍼블리셔로서의 경험을 바탕으로 더 나은 웹 애플리케이션을 만드는 데 기여하고자 하는 열망을 키워주었습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="skills_wrap">
            <h2 className="mt40" data-aos="fade-up">
              Skills
            </h2>
            <ul className="skill_list" data-aos="fade-up" data-aos-delay="200">
              <li>
                #html
              </li>
              <li>
                #css
              </li>
              <li>
                #JavaScript
              </li>
              <li>
                #React
              </li>
              <li>
                #Vue
              </li>
              <li>
                #node.js(이론이해)
              </li>
              <li>
                #Spring(이론이해)
              </li>
              <li>
                #Sql(이론이해)
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="sec_03">
        <div className="project_wrap">
          <div className="container">
            <h2 data-aos="fade-up">
              Project
            </h2>
          </div>
          <div className='project-inner'>
            <div className='container'>
              <ul className="prject_list">
                {projectList.map((project) => (
                  <li key={project.no} onClick={() => popupShow(project)}>
                    <div className='number'>
                      0{project.no}
                    </div>
                    <h3>{project.pjtName}</h3>
                    <p>{project.exp}</p>
                    <div className='seeMore'>
                      +
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            
          </div>
        </div>
      </section>
      <section className="sec_04">
        <div className="container">
          <p className="end_text" data-aos="fade-up">
            Thank For Watch !
          </p>
        </div>
      </section>
      {showPopup && (
        <div className="popup">
          <div className="inner">
            <div className='scroll'>
              <div className="title">
                {popupData.pjtName}
                <div className='popupClose' onClick={popupHide}>
                  <FontAwesomeIcon icon={faTimes} size="2x" />
                </div>
              </div>
              <div className='subTitle'>
                {popupData.subTitle}
              </div>
              
              <div className="text">
                {popupData.popupText}
              </div>
              <div className="img">
                {popupData.images.length > 0 ? (
                  <Slider {...sliderSettings}>
                    {popupData.images.map((image, index) => (
                      <div key={index}>
                        <img src={image} alt={`slide-${index}`} />
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <p>이미지가 없습니다.</p>
                )}
              </div>
              <div className="button Link" onClick={() => window.open(popupData.url, "_blank")}>
                보러가기
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
